<template>

<app-content :loading="is.loading">

	<app-content-head :title="item.name" :back="$route.meta.back" :readonly="$authorised('con/volunteers/access', 'read')" v-if="!is.loading">

	</app-content-head>

	<app-content-body>

		<app-content-summary>

			<app-content-summary-item label="No." :value="item.reference" />
			<app-content-summary-item label="Ref." :value="item.reference_random" />
			<app-content-summary-item label="Name" :value="item.name" />
			<app-content-summary-item label="E-mail" :grow="true" :value="item.email" />
			<app-content-summary-item label="Roles" :value="item.count.volunteer.roles" />
			<app-content-summary-item label="Slots" :value="item.count.volunteer.slots" />

		</app-content-summary>

		<app-content-box icon="volunteers.slots" :not-padded="true" title="Assignments" v-if="!isNew">

			<app-table :empty="!references.assignments.length" emptyMessage="There are no assignments.">

				<app-table-head :layout="layout">

					<app-table-column text="Role" />
					<app-table-column text="Slot" />
					<app-table-column />

				</app-table-head>

				<app-table-body>

					<app-table-row v-on:click="onEditClick(assignment)" :layout="layout" v-for="assignment in references.assignments" :key="assignment.id">

						<app-table-cell-text :text="assignment.role.name" />
						<app-table-cell-text :text="assignment.slot.name" />
						<app-table-cell-action icon="remove" theme="red" v-tooltip="'Remove assignment'" v-on:click="onRemoveClick(assignment)" />

					</app-table-row>

				</app-table-body>

			</app-table>

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			layout: '300px auto 24px',
			readonly: this.$authorised('con/volunteers/access', 'read'),
			references: {
				assignments: []
			},
			model: {
				id: false
			}
		}

	},

	methods: {

		onEditClick: function(assignment) {

			this.$router.push({
				name: 'Convention.Volunteers.Assignments.Edit',
				params: {
					id: assignment.id,
					role: assignment.role.id
				},
				query: {
					redirect: 'badge',
					badge: this.item.id
				}
			})

		},

		onRemoveClick: function(assignment) {

			this.$router.push({
				name: 'Convention.Volunteers.Assignments.Delete',
				params: {
					id: assignment.id,
					role: assignment.role.id
				},
				query: {
					redirect: 'badge',
					badge: this.item.id
				}
			})

		}

	}

}

</script>

<style scoped>

</style>